import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import axios from 'axios';
import Button from '@mui/material/Button';

// модальное окно
import TextField from '@mui/material/TextField';

import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import ModalAfterMessage from '../CalcPage/ModalAfterMessage';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  // данные бота и чата для отправки в телегу
  const botToken = '5358830966:AAGx475XYmMDV0tCLPhXHN_ZUAzpWzWn_Ro';
  const chatId = '-1001857388319';

  // состояние окон модалки

  const [input, setInput] = useState({
    complect: 'Форма в контактах',
    clientname: '',
    phone: '',
    email: '',
    question: '',
  });

  // ручка для изменения состояния input при вводе данных
  const changeInputHandler = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // ручка для отправки сообщения
  const handleSendMessageCalc = () => {
    axios.post(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${JSON.stringify(input)}`, input);
    // .then((res) => console.log(res.data, '===это res==='));

    setInput({
      complect: 'Форма в контактах',
      clientname: '',
      phone: '',
      email: '',
      question: '',
    });
  };

  // состояние и ручка для модалки после отправки заказа
  const [open, setOpen] = React.useState(false);
  const handleOpenOrderModal = () => {
    setOpen(true);
    setTimeout(() => { setOpen(false); }, 3500);
  };
  const handleCloseOrderModal = () => setOpen(false);

  return (
    <Box
      id="contacts"
      sx={{
        flexGrow: 1, background: '#161722', paddingTop: '90px',
      }}
    >
      <Container>

        <Grid container spacing={2}>
          <Grid xs={12} sm={12} md={6}>
            <Grid xs={12} sm={12} md={12}>
              <Item sx={{
                boxShadow: 'none',
                textAlign: { sm: 'center', md: 'left', background: '#161722' },
              }}
              >
                <div style={{ marginTop: '10px' }}>
                  <div style={{
                    position: 'absolute',
                    marginTop: '3px',
                    width: '8px',
                    height: '8px',
                    background: '#FA541C',
                    borderRadius: '100%',
                  }}
                  />
                  <h4 style={{
                    color: '#919eab',
                    textAlign: 'left',
                    fontWeight: 400,
                    fontSize: '13px',
                    marginLeft: '15px',
                  }}
                  >
                    СВЯЖИТЕСЬ С НАМИ
                  </h4>
                  <h1 style={{
                    color: '#FA541C',
                    //   textAlign: 'left',
                    fontSize: '45px',
                  }}
                  >
                    Наши контакты

                  </h1>
                  <h4 style={{
                    color: 'white',
                    //   textAlign: 'left',
                    fontWeight: 300,
                    //   width: '70%',
                    // fontStyle: 'italic',
                    fontSize: '16px',
                  }}
                  >
                    Задайте интересующий вас вопрос
                    <br />
                    на этой странице
                    <br />
                    или свяжитесь с нами любым другим,
                    <br />
                    удобным для вас способом.
                    <br />
                    Мы всегда рады новым знакомствам!
                  </h4>
                </div>
              </Item>
            </Grid>

            {/* айтем для контактов */}
            <Item sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row', md: 'row' },
              alignItems: 'start',
              // mt: '15px',
              background: '#161722',
              boxShadow: 'none',
            }}
            >
              {/* адрес телефон */}
              <Grid xs={12} sm={12} md={6}>
                <Item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    // mt: '15px',
                    background: '#161722',
                    boxShadow: 'none',
                  }}
                >
                  <div
                    className="ourContacts"
                    style={{
                      display: 'flex', flexDirection: 'row',
                    }}
                  >
                    <span className="material-symbols-outlined contactIcon">
                      mail
                    </span>
                    <h3 style={{ color: 'white', fontWeight: '300', marginLeft: '10px' }}>
                      <a href="mailto:sale@geotag.pro">sale@geotag.pro</a>

                    </h3>
                  </div>

                  <div
                    className="ourContacts"
                    style={{
                      display: 'flex', flexDirection: 'row',
                    }}
                  >
                    <span className="material-symbols-outlined contactIcon">
                      call
                    </span>
                    <h3 style={{ color: 'white', fontWeight: '300', marginLeft: '10px' }}>
                      <a href="tel:+74993917374">+7 499 391 73 74</a>

                    </h3>
                  </div>

                  <div style={{
                    display: 'flex', flexDirection: 'row',
                  }}
                  >
                    <span className="material-symbols-outlined contactIcon">
                      location_on
                    </span>
                    <h3 style={{
                      color: 'white',
                      fontWeight: '300',
                      marginLeft: '10px',
                      textAlign: 'left',
                      // verticalAlign: 'middle',
                    }}
                    >
                      г. Москва, ул. Привольная, д. 27к1
                    </h3>
                  </div>
                </Item>
              </Grid>

              {/* юрадрес и реквизиты */}
              <Grid xs={12} sm={12} md={6}>
                <Item
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    // mt: '15px',
                    background: '#161722',
                    boxShadow: 'none',
                  }}
                >
                  <h3 style={{
                    color: 'white', fontWeight: '300', marginLeft: '10px', textAlign: 'left',
                  }}
                  >
                    Юридический адрес:
                    <p />
                    170039, Тверская обл.,
                    {' '}
                    <br />
                    г. Тверь,
                    {' '}
                    Улица Фрунзе, д. 1Б, офис 6/1
                    <p />
                    <a
                      className="download"
                      href="./docs/GeotagBankDetails.doc"
                      style={{
                        textAlign: 'left', textDecoration: 'none', fontSize: '14.8px',
                      }}
                    >
                      Скачать реквизиты
                      <span style={{ fontSize: '20px', marginLeft: '2px' }}>→</span>
                    </a>
                  </h3>
                </Item>
              </Grid>
            </Item>
          </Grid>

          <Grid xs={12} sm={12} md={6}>
            {/* форма для запроса */}
            <Item sx={{
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 'none',
              alignItems: { xs: 'center', sm: 'center', md: 'start' },
              background: '#161722',
              mt: { xs: '-10px', sm: '-10px', md: '80px' },
            }}
            >
              <TextField
              // autoFocus
                className="contactForm"
                onChange={changeInputHandler}
                margin="dense"
                id="name"
                name="clientname"
                value={input.clientname}
                label="Ваше имя"
                type="text"
                fullWidth
              />
              <TextField
              // autoFocus
                className="contactForm"
                onChange={changeInputHandler}
                margin="dense"
                id="name"
                name="phone"
                value={input.phone}
                label="Ваш номер телефона"
                type="text"
                fullWidth
              />
              <TextField
              // autoFocus
                className="contactForm"
                onChange={changeInputHandler}
                margin="dense"
                id="name"
                name="email"
                value={input.email}
                label="Ваша электронная почта"
                type="email"
                fullWidth
              />
              <TextField
              // autoFocus
                className="contactForm"
                onChange={changeInputHandler}
                margin="dense"
                id="name"
                name="question"
                value={input.question}
                multiline
                rows={4}
                label="Ваш вопрос"
                type="text"
                fullWidth
              />
              {/* <Button onClick={handleClose}>X</Button> */}
              <Button
                className="buttonOffer"
                sx={{
                  width: '200px',
                  // justifyContent: 'center',
                  // margin: 'auto',
                  // marginRight: '200px',
                  marginBottom: '15px',
                  marginTop: '25px',
                }}
                onClick={() => {
                // handleClose();
                  handleSendMessageCalc();
                  handleOpenOrderModal();
                }}
              >
                ОТПРАВИТЬ
              </Button>
            </Item>
          </Grid>
          {/* модалка после отправки мессаги */}
          <ModalAfterMessage
            handleOpenOrderModal={handleOpenOrderModal}
            handleCloseOrderModal={handleCloseOrderModal}
            open={open}
          />

          {/* карта */}
          <Grid xs={12}>
            <Item sx={{ boxShadow: 'none', background: '#161722' }}>
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <YMaps style={{ display: 'flex', justifyContent: 'center' }}>
                  {/* <div>My awesome application with maps!</div> */}
                  <Map style={{ width: '100%', height: '500px' }} defaultState={{ center: [55.695266, 37.849481], zoom: 15 }}>
                    <Placemark
                      defaultGeometry={[55.695266, 37.849481]}
                    />
                  </Map>
                </YMaps>
              </div>
              {/* <iframe title="geotag" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2248.6382977705575!2d37.84844731560036!3d55.69527600389527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414ab6086aa025eb%3A0x5d2af70e3b23bf59!2z0J_RgNC40LLQvtC70YzQvdCw0Y8g0YPQuy4sIDI3LCDQnNC-0YHQutCy0LAsIDEwOTE1Mw!5e0!3m2!1sru!2sru!4v1680289918953!5m2!1sru!2sru" width="100%" height="500" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
              {/* <div style={{ position: 'relative', overflow: 'hidden' }}>
                <a
                  href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps"
                  style={{
                    color: '#eee', fontSize: '12px', position: 'absolute', top: '0px',
                  }}
                >
                  Москва
                </a>
                <a
                  href="https://yandex.ru/maps/213/moscow/house/privolnaya_ulitsa_27k1/Z04YfgNpTU0GQFtvfXp4dH5lYg==/?ll=37.849481%2C55.695266&utm_medium=mapframe&utm_source=maps&z=16.56"
                  style={{
                    color: '#eee', fontSize: '12px', position: 'absolute', top: '14px',
                  }}
                >
                  Привольная улица, 27к1 — Яндекс Карты
                </a>
                <iframe
                  title="map"
                  src="https://yandex.ru/map-widget/v1/?ll=37.849481%2C55.695266&mode=search&ol=geo&ouri=ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgg1NjY3Mzk5NhJC0KDQvtGB0YHQuNGPLCDQnNC%2B0YHQutCy0LAsINCf0YDQuNCy0L7Qu9GM0L3QsNGPINGD0LvQuNGG0LAsIDI30LoxIgoN32UXQhX0x15C&z=16.56"
                  width="100%"
                  height="500px"
                  allowFullScreen
                  style={{ position: 'relative', border: 'none' }}
                />
              </div> */}
            </Item>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
