import React from 'react';
import Container from '@mui/material/Container';

import FAQAccordeon from './FAQAccordeon';
import FAQFuel from './FAQFuel';
import HiddenTracker from './HiddenTracker';
import DriverControl from './DriverControl';
import Angle from './Angle';
import Temperature from './Temperature';
import BlockEngine from './BlockEngine';
import Video from './Video';
import RNIS from './RNIS';
// import Wialon from './Wialon';
import WhatTransport from './WhatTransport';
import WhatMonitoring from './WhatMonitoring';

export default function Accordeon() {
  return (
    <Container
      id="faq-link"
      style={{
        paddingTop: '70px', paddingBottom: '60px', paddingLeft: '0px', paddingRight: '0px',
      }}
    >
      <div style={{ margin: '0 24px 0 24px' }}>
        <div style={{
          position: 'absolute',
          marginTop: '3px',
          width: '8px',
          height: '8px',
          background: '#FA541C',
          borderRadius: '100%',
        }}
        />
        <h4 style={{
          color: '#919eab',
          textAlign: 'left',
          fontWeight: 400,
          fontSize: '13px',
          marginLeft: '15px',
        }}
        >
          ВСЕ ЧТО ВЫ ХОТЕЛИ ЗНАТЬ ПРО МОНИТОРИНГ ТРАНСПОРТА
        </h4>
        <h1 style={{
          color: '#2f2f2f',
          textAlign: 'left',
          fontSize: '45px',
        }}
        >
          FAQ

        </h1>
        {/* <h4 style={{
          color: '#919eab',
          textAlign: 'left',
          fontWeight: 300,
          width: '70%',
        }}
        >
          В этом разделе вы можете выбрать именно те услуги, которые вам нужны.
          Получив вашу заявку мы немедленно свяжемся с вами и предоставим подробный расчет
          нескольких вариантов реализации ваших задач
        </h4> */}
      </div>
      <WhatMonitoring />
      <FAQAccordeon />
      <FAQFuel />
      <HiddenTracker />
      <DriverControl />
      <Angle />
      <Temperature />
      <BlockEngine />
      <Video />
      <RNIS />
      {/* <Wialon /> */}
      <WhatTransport />
    </Container>
  );
}
