import React, { useState, useRef, useEffect } from 'react';
import './faq-styles.css';
import { FiPlus } from 'react-icons/fi';
import Container from '@mui/material/Container';

export default function DriverControl() {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <Container sx={{ padding: '2px' }}>

      {/* контроль вождения */}

      <div className="faq">
        <button
          type="button"
          className={`question-section ${active}`}
          onClick={toggleAccordion}
        >
          <div>
            <div className="question-align">
              <h3 className="question-style" style={{ marginLeft: '8px' }}>
                Как контролировать стиль вождения?
              </h3>
              <span
                className="material-symbols-outlined"
                style={{ color: '#1b6aae' }}
              >
                {active ? 'close' : 'add'}
              </span>
              {/* <FiPlus
                className={active ? 'question-icon rotate' : 'question-icon'}
              /> */}
            </div>
            <div
              ref={contentRef}
              className={active ? 'answer answer-divider' : 'answer'}
            >
              <p />
              <h4 className="question-style">
                Для контроля стиля вождения
                можно использовать возможности систем мониторинга
                транспорта (например ГлонассСофт или Pilot):
              </h4>
              <span>
                {/* Для контроля стиля вождения с помощью, например, Wialon или Pilot
                можно использовать функциональные возможности системы, такие как: */}

                <h4 className="question-style">
                  Мониторинг скорости:
                </h4>
                позволяет установить предельную
                скорость для каждого автомобиля и запрограммировать систему на отправку
                уведомлений в случае превышения установленного ограничения.

                <h4 className="question-style">
                  Мониторинг топливного расхода:
                </h4>
                позволяет отслеживать расход топлива и
                установить оптимальный режим езды для экономии топлива. Например, водитель,
                который часто резко тормозит или быстро разгоняется, может использовать больше
                топлива, чем тот, кто ездит более плавно и спокойно.

                <h4 className="question-style">
                  Анализ траектории движения:
                </h4>
                система может проанализировать траекторию движения
                автомобиля и предоставить информацию о времени и месте остановок, скорости
                движения, качестве покрытия дорог и другие данные, чтобы определить, насколько
                плавно и безопасно водитель управляет автомобилем.

                <h4 className="question-style">
                  Настройка системы анализа вождения:
                </h4>
                Некоторые системы имеют функцию настройки
                анализа вождения, которая позволяет сравнивать данные о стиле вождения нескольких
                водителей и выделять наиболее безопасных и экономных водителей.
                <p />
                В целом, используя все вышеперечисленные возможности подобных систем, вы можете
                контролировать стиль вождения вашего персонала и работать над улучшением
                безопасности, экономии топлива и эффективности процессов в вашей компании.
              </span>
              <p />
            </div>
          </div>
        </button>
      </div>
    </Container>
  );
}
